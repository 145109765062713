import React from 'react';
import { Dropdown as BasicDropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';

const Dropdown = ({ className, dir, title, items, noArrow, toggleClass, variant }) => {
  const noArrowClass = noArrow ? 'dropdown-no-arrow' : '';
  const customClass = `${toggleClass} ${noArrowClass}`;

  return (
    <BasicDropdown className={`${dir} ${className}`}>
      <BasicDropdown.Toggle id="dropdown-basic" variant={variant} className={customClass}>
        {title}
      </BasicDropdown.Toggle>
      <BasicDropdown.Menu className="dropdown-menu-right p-1">
        {items.map(item => (
          <BasicDropdown.Item key={item.key} {...item.props}>
            {item.title}
          </BasicDropdown.Item>
        ))}
      </BasicDropdown.Menu>
    </BasicDropdown>
  );
};

Dropdown.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
};

Dropdown.defaultProps = {
  className: '',
  dir: '', // dropup, dropright, dropleft. Default: dropdown
  items: [],
  title: '',
  toggleClass: '',
  variant: 'primary',
};

export default Dropdown;
