import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { Carousel, Loader } from '../index';

const PageHeader = ({ id, carouselSlides, children, className, onRequest }) => (
  <Row id={id}>
    {onRequest && <Loader />}
    {carouselSlides && <Carousel slides={carouselSlides} />}
    <Col md={{ span: 10, offset: 1 }} xl={{ span: 8, offset: 2 }}>
      <Row className={`page-header d-flex justify-content-center ${className || ''}`}>{children}</Row>
    </Col>
  </Row>
);

export default PageHeader;
