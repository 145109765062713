import React from 'react';

import selfPortrait from '../assets/images/myself-3.png';
import defaultLogo from '../assets/images/default-logo-2.png';

const Home = () => {
  return (
    <section style={{ height: '100vh' }}>
      <ContentBox />
      <Wave />
    </section>
  );
};

const Wave = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
    <path
      fill="#dbd7d1"
      fillOpacity="1"
      d="M0,32L120,69.3C240,107,480,181,720,181.3C960,181,1200,107,1320,69.3L1440,32L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
    ></path>
  </svg>
);

const ContentBox = () => (
  <div className="bg-light d-flex justify-content-center align-items-center" style={{ zIndex: 0, height: '70vh' }}>
    <DotSvg />
    <CenteredImageSquare />
  </div>
);

const CenteredImageSquare = () => (
  <>
    <div
      className="bg-light rounded border-primary"
      style={{
        zIndex: 2,
        width: '300px',
        height: '30vh',
        border: '5px solid',
        boxShadow: 'inset 0 -40px 30px -15px #afa797',
      }}
    >
      <img src={defaultLogo} alt="default-logo" style={{ width: '150px', padding: '2rem 0 0 2rem' }} />
    </div>

    <div style={{ zIndex: 2, position: 'absolute', top: '230px' }}>
      <img src={selfPortrait} alt="self-portrait" style={{ width: '200px' }} />
    </div>
  </>
);

const DotSvg = () => (
  <div className="position-absolute" style={{ zIndex: 1, top: 0, left: 0 }}>
    <svg height="85vh" width="100vw" xmlns="http://www.w3.org/2000/svg">
      <CircleColumn1 />
      <CircleColumn2 />
      <CircleColumn3 />
      <CircleColumn4 />
      <CircleColumn5 />
      <CircleColumn6 />
      <CircleColumn7 />
      <CircleColumn8 />
      <CircleColumn9 />
      <CircleColumn10 />
      <CircleColumn11 />
    </svg>
  </div>
);

const CircleColumn1 = () => (
  <>
    <circle r={15} cx={125} cy={140} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={125} cy={300} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={125} cy={460} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={125} cy={600} fill="#afa797" opacity="0.15" />;
  </>
);

const CircleColumn2 = () => (
  <>
    <circle r={15} cx={250} cy={80} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={250} cy={225} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={250} cy={390} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={250} cy={550} fill="#afa797" opacity="0.15" />;
  </>
);

const CircleColumn3 = () => (
  <>
    <circle r={15} cx={375} cy={140} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={375} cy={300} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={375} cy={460} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={375} cy={630} fill="#afa797" opacity="0.15" />;
  </>
);

const CircleColumn4 = () => (
  <>
    <circle r={15} cx={500} cy={80} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={500} cy={225} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={500} cy={390} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={500} cy={550} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={500} cy={700} fill="#afa797" opacity="0.15" />;
  </>
);

const CircleColumn5 = () => (
  <>
    <circle r={15} cx={625} cy={140} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={625} cy={300} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={625} cy={460} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={625} cy={630} fill="#afa797" opacity="0.15" />;
  </>
);

const CircleColumn6 = () => (
  <>
    <circle r={15} cx={750} cy={80} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={750} cy={225} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={750} cy={390} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={750} cy={550} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={750} cy={700} fill="#afa797" opacity="0.15" />;
  </>
);

const CircleColumn7 = () => (
  <>
    <circle r={15} cx={875} cy={140} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={875} cy={300} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={875} cy={460} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={875} cy={630} fill="#afa797" opacity="0.15" />;
  </>
);

const CircleColumn8 = () => (
  <>
    <circle r={15} cx={1000} cy={80} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={1000} cy={225} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={1000} cy={390} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={1000} cy={550} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={1000} cy={700} fill="#afa797" opacity="0.15" />;
  </>
);

const CircleColumn9 = () => (
  <>
    <circle r={15} cx={1125} cy={140} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={1125} cy={300} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={1125} cy={460} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={1125} cy={630} fill="#afa797" opacity="0.15" />;
  </>
);

const CircleColumn10 = () => (
  <>
    <circle r={15} cx={1250} cy={80} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={1250} cy={225} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={1250} cy={390} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={1250} cy={550} fill="#afa797" opacity="0.15" />;
  </>
);

const CircleColumn11 = () => (
  <>
    <circle r={15} cx={1375} cy={140} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={1375} cy={300} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={1375} cy={460} fill="#afa797" opacity="0.15" />;
    <circle r={15} cx={1375} cy={600} fill="#afa797" opacity="0.15" />;
  </>
);

export default Home;
