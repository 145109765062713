import React from 'react';
import { Container } from 'react-bootstrap';
import { BrowserRouter as Router } from 'react-router-dom';

import { Footer, Navbar } from './app/components';
import { About, ContactIndex, Home } from './app/pages';

import './index.scss';

const App = () => (
  <Router>
    <Navbar />
    <Container fluid className="web-layout-content">
      <div id="home" style={{ minHeight: '80vh' }}>
        <Home />
      </div>
      <div id="about" style={{ minHeight: '80vh' }}>
        <About />
      </div>
      <div id="contact" style={{ minHeight: '80vh' }}>
        <ContactIndex />
      </div>
    </Container>
    <Footer />
  </Router>
);

export default App;
