import runtimeEnv from '@mars/heroku-js-runtime-env';

const env = runtimeEnv();

const EMAILJS_SERVICE_ID = env.REACT_APP_EMAILJS_SERVICE_ID;
const EMAILJS_TEMPLATE_ID = env.REACT_APP_EMAILJS_TEMPLATE_ID;
const EMAILJS_USER_ID = env.REACT_APP_EMAILJS_USER_ID;
const GITHUB_PROFILE = env.REACT_APP_GITHUB;
const LINKEDIN_PROFILE = env.REACT_APP_LINKEDIN;

export { EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, EMAILJS_USER_ID, GITHUB_PROFILE, LINKEDIN_PROFILE };
