import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import { send } from 'emailjs-com';
import snakeCaseKeys from 'snakecase-keys';

import { PageHeader } from '../../components';
import { EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, EMAILJS_USER_ID } from '../../config/utils';
import ContactForm from './ContactForm';

const basicContact = {
  fromName: '',
  message: '',
  replyTo: '',
};

const ContactIndex = () => {
  const [onRequest, setOnRequest] = useState(false);
  const [submitMsg, setSubmitMsg] = useState({});
  const { t } = useTranslation();

  const handleResponse = (message, setSubmitting, resetForm) => {
    if (resetForm) resetForm(basicContact);
    setSubmitMsg(message);
    setSubmitting(false);
    setOnRequest(false);
  };

  const handleEmailRequest = (params, setSubmitting, resetForm) => {
    setOnRequest(true);
    setSubmitMsg('');
    const toSend = snakeCaseKeys(params.contact);
    send(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, toSend, EMAILJS_USER_ID)
      .then(() => handleResponse({ color: 'success', message: t('config.message.success') }, setSubmitting, resetForm))
      .catch(() => handleResponse({ color: 'danger', message: t('config.message.tryAgain') }, setSubmitting));
  };

  return (
    <PageHeader onRequest={onRequest}>
      <Col xs={11} sm={8} md={7} lg={6} xl={5}>
        <Row>
          <Col md={12}>
            <h3 className="text-center mb-4">{t('contact.title')}</h3>
          </Col>
          {submitMsg.message && (
            <Col md={12}>
              <p className={`text-center text-${submitMsg.color}`}>{submitMsg.message}</p>
            </Col>
          )}
          <Col md={12}>
            <ContactForm t={t} contact={basicContact} formRequest={handleEmailRequest} />
          </Col>
        </Row>
      </Col>
    </PageHeader>
  );
};

export default ContactIndex;
