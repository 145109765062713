import React from 'react';
import PropTypes from 'prop-types';
import { Carousel as BasicCarousel } from 'react-bootstrap';

const Carousel = ({ slides, className }) => (
  <BasicCarousel className={className} controls={slides.length > 1} indicators={slides.length > 1}>
    {slides.map(item => (
      <BasicCarousel.Item key={`carousel-${item.id}`}>
        <img src={item.fileUrl} alt={item.caption} className="d-flex w-100" />
        <BasicCarousel.Caption>{item.caption && <h3>{item.caption}</h3>}</BasicCarousel.Caption>
      </BasicCarousel.Item>
    ))}
  </BasicCarousel>
);

Carousel.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      caption: PropTypes.string,
      className: PropTypes.string,
      fileUrl: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Carousel;
