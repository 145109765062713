import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import i18next from 'i18next';

import { isPar } from '../../../services/utils.js';

const LanguageToggle = () => {
  const languages = i18next.options.whitelist.filter(language => language !== 'cimode');
  const currentLanguage = i18next.language; // localStorage.getItem('i18nextLng');
  const { t } = useTranslation();

  const handleOnClick = value => i18next.changeLanguage(value);

  return (
    <Row className="language-toggle small">
      {languages.map((language, index) => {
        const isActive = language === currentLanguage ? 'active' : '';
        const side = isPar(index + 1) ? 'right' : 'left';
        return (
          <Col
            xs={6}
            key={`language-${index.toString()}`}
            className={`language-toggle-item custom-rounded-${side} ${isActive}`}
            onClick={() => handleOnClick(`${language}`)}
          >
            {t(`${language}`)}
          </Col>
        );
      })}
    </Row>
  );
};

export default LanguageToggle;
