import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'react-bootstrap';
import { GitHub, Linkedin } from 'react-feather';

import { GITHUB_PROFILE, LINKEDIN_PROFILE } from '../../config/utils';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Row className="web-layout-footer small text-uppercase mt-4">
      <Col
        md={{ span: 10, offset: 1 }}
        xl={{ span: 8, offset: 2 }}
        className="d-flex justify-content-between align-items-center"
      >
        <p className="m-0">{t('config.footer.poweredBy')} Lowtjie</p>
        <Row>
          <Col className="pr-1">
            <Button variant="circle-light" onClick={() => window.open(LINKEDIN_PROFILE, '_blank', 'noopener')}>
              <Linkedin />
            </Button>
          </Col>
          <Col className="pl-0">
            <Button variant="circle-light" onClick={() => window.open(GITHUB_PROFILE, '_blank', 'noopener')}>
              <GitHub />
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Footer;
