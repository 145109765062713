import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import { Globe, MessageCircle, MapPin, User } from 'react-feather';

import { PageHeader } from '../components';
import { calculateYears } from '../services/utils';

const About = () => (
  <PageHeader>
    <Col xs={11}>
      <Summary />
      <Row className="grid-two-columns">
        <PersonalInformation />
        <WorkExperience />
        <Education />
        <Skills />
      </Row>
    </Col>
  </PageHeader>
);

const Summary = () => {
  const { t } = useTranslation();

  const sendParams = {
    age: calculateYears('06/05/1994'),
    years: calculateYears('08/04/2017'),
    workExperience: calculateYears('15/04/2019'),
  };

  return (
    <Col xs={12} className="mb-5">
      <p className="mb-0">{t('about.summary', sendParams)}</p>
    </Col>
  );
};

const PersonalInformation = () => {
  const { t: personalInformation } = useTranslation('translation', { keyPrefix: 'about.personalInformation' });

  return (
    <Col xs={12} className="mb-5">
      <h5 className="text-uppercase font-weight-bold highlighted-text-primary" style={{ maxWidth: 'fit-content' }}>
        {personalInformation('title')}
      </h5>
      <p className="mb-0">
        <span className="text-primary-lighten">
          <User size={20} />
        </span>
        <span className="font-weight-bold mx-1">{personalInformation('gender.key')}:</span>
        {personalInformation('gender.value')}
      </p>
      <p className="mb-0">
        <span className="text-primary-lighten">
          <MessageCircle size={20} />
        </span>
        <span className="font-weight-bold mx-1">{personalInformation('motherTongue.key')}:</span>
        {personalInformation('motherTongue.value')}
      </p>
      <p className="mb-0">
        <span className="text-primary-lighten">
          <Globe size={20} />
        </span>
        <span className="font-weight-bold mx-1">{personalInformation('nationality.key')}:</span>
        {personalInformation('nationality.value')}
      </p>
      <p className="mb-0">
        <span className="text-primary-lighten">
          <MapPin size={20} />
        </span>
        <span className="font-weight-bold mx-1">{personalInformation('location.key')}:</span>
        {personalInformation('location.value')}
      </p>
    </Col>
  );
};

const WorkExperience = () => {
  const { t: workExperience } = useTranslation('translation', { keyPrefix: 'about.workExperience' });

  return (
    <Col xs={12} className="mb-5">
      <h5 className="text-uppercase font-weight-bold highlighted-text-primary" style={{ maxWidth: 'fit-content' }}>
        {workExperience('title')}
      </h5>
      <p className="mb-0">
        <span className="font-weight-bold mr-1">{workExperience('nnodes.companyName')}</span>
      </p>
      <p className="mb-0">
        {workExperience('nnodes.location.key')}: {workExperience('nnodes.location.value')}
      </p>
      <p className="mb-0 bullet-element">
        {workExperience('nnodes.positions.lead.startYear')}-{workExperience('nnodes.positions.lead.endYear')}
        <TextSplitter />
        {workExperience('nnodes.positions.lead.name')}
      </p>
      <p className="mb-0 bullet-element">
        {workExperience('nnodes.positions.developer.startYear')}-{workExperience('nnodes.positions.developer.endYear')}
        <TextSplitter />
        {workExperience('nnodes.positions.developer.name')}
      </p>
    </Col>
  );
};

const Education = () => {
  const { t: education } = useTranslation('translation', { keyPrefix: 'about.education' });

  return (
    <Col xs={12} className="mb-5">
      <h5 className="text-uppercase font-weight-bold highlighted-text-primary" style={{ maxWidth: 'fit-content' }}>
        {education('title')}
      </h5>
      <div className="mb-3">
        <p className="mb-0">
          <span className="font-weight-bold mr-1">{education('englishPath.companyName')}</span>
        </p>
        <p className="mb-0">
          {education('englishPath.location.key')}: {education('englishPath.location.value')}
        </p>
        <p className="mb-0 bullet-element">
          {education('englishPath.startYear')}-{education('englishPath.endYear')}
          <TextSplitter />
          {education('englishPath.certification.name')}
        </p>
      </div>
      <div>
        <p className="mb-0">
          <span className="font-weight-bold mr-1">{education('desafioLatam.companyName')}</span>
        </p>
        <p className="mb-0">
          {education('desafioLatam.location.key')}: {education('desafioLatam.location.value')}
        </p>
        <p className="mb-0 bullet-element">
          {education('desafioLatam.startYear')}-{education('desafioLatam.endYear')}
          <TextSplitter />
          {education('desafioLatam.certification.name')}
        </p>
      </div>
    </Col>
  );
};

const Skills = () => {
  const { t: skills } = useTranslation('translation', { keyPrefix: 'about.skills' });

  return (
    <Col xs={12} className="mb-5">
      <h5 className="text-uppercase font-weight-bold highlighted-text-primary" style={{ maxWidth: 'fit-content' }}>
        {skills('title')}
      </h5>
      <p className="mb-0 bullet-element">{skills('language')}</p>
      <p className="mb-0 bullet-element">{skills('dataBase')}</p>
      <p className="mb-0 bullet-element">{skills('library')}</p>
      <p className="mb-0 bullet-element">{skills('framework')}</p>
      <p className="mb-0 bullet-element">{skills('versionControl')}</p>
      <p className="mb-0 bullet-element">{skills('agileMethods')}</p>
      <p className="mb-0 bullet-element">{skills('interpersonalCommunication')}</p>
    </Col>
  );
};

const TextSplitter = () => <span className="mx-2">|</span>;

export default About;
