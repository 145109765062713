import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'react-bootstrap';
import { Field, Form, getIn, withFormik } from 'formik';
import * as Yup from 'yup';

import { FormikInput, FormikTextarea } from '../../components';

const ContactForm = ({ errors, isSubmitting, touched }) => {
  const { t } = useTranslation();
  return (
    <Form>
      <Row>
        <Col md={12}>
          <Field name="contact[fromName]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label={t('contact.name')}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <Field name="contact[replyTo]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label={t('contact.email')}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <Field name="contact[message]">
            {({ field }) => (
              <FormikTextarea
                {...field}
                abbr
                label={t('contact.message')}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                className="no-resizing"
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button block type="submit" variant="primary" disabled={isSubmitting}>
            {t('contact.send')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = ({ contact }) => ({ contact });

const validationSchema = ({ t }) =>
  Yup.object().shape({
    contact: Yup.object().shape({
      fromName: Yup.string().required(t('contact.yup.fromName')),
      message: Yup.string().required(t('contact.yup.message')),
      replyTo: Yup.string().required(t('contact.yup.replyTo')).email(t('contact.yup.invalidEmail')),
    }),
  });

const handleSubmit = (values, { props, setSubmitting, resetForm }) => {
  const { formRequest } = props;
  formRequest(values, setSubmitting, resetForm);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
})(ContactForm);
